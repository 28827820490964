import { useQuery } from 'react-query'
import { api } from '#app/utils/api'

export const useEstimatedPrice = (
	objectSize: string,
	additionalServicesDuration = 0,
) =>
	useQuery({
		queryKey: ['estimate', objectSize],
		queryFn: () =>
			api
				.getEstimate(objectSize, 'precise', additionalServicesDuration)
				.then(x => {
					if ('errors' in x.data) throw x.data.errors
					return x.data
				}),
	})
